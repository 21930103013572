import React, {useState, useEffect} from "react"
import styled from 'styled-components';
import storage from 'local-storage-fallback'

//Components
import CardFullWidth from '../components/CardFullWidth';
import CardHalfWidth from '../components/CardHalfWidth';
import Navbar from '../components/Navbar';

import {ThemeProvider, createGlobalStyle } from 'styled-components'; 
import style from 'styled-theming'



const getBackground = style('mode', {
  light: '#fff',
  dark: '#000'
})

const getForeground = style('mode', {
  light: '#000',
  dark: '#fff'
})

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${getBackground};
    color: ${getForeground};
  }
`

function getInitialTheme() {
  const savedTheme = storage.getItem('theme')
  return savedTheme ? JSON.parse(savedTheme) : { mode: 'light' }
}

const IndexPage = () => {
  const [theme, setTheme] = useState(getInitialTheme)
  useEffect (
    () => {
      storage.setItem('theme', JSON.stringify(theme));
    },
    [theme]
  );
  return (
    <ThemeProvider theme={theme}>
      <>
      <GlobalStyle />
        <Grid>
        <Navbar />

        <button 
          onClick={e =>
            setTheme(
              theme.mode === 'dark'
                ? { mode: 'light'} 
                : { mode: 'dark'}
            )
          }
        >
          Toggle Theme
        </button>

          <Header>
            <h1 className="header__title">Hello, <br /> I'm Nate</h1>
            <p className="header__sub-title">
              Product Designer & Coder based in the Midwest.<br /> 
              Specialized in designing meaningful and intuitives interfaces <br /> 
              for mobile and web devices. 
            </p>
            <a className="header__button">Let's Talk</a>
          </Header>

          <CardFullWidth 
            title="Buoy" 
            subTitle="Personal Venture • 2019 - 2020 • App + Landing Page Design & Dev"
            projectLink="/projects/Buoy"
          />
          <GridHalf>
            <CardHalfWidth 
              title="Bootstrap Design" 
              subTitle="Personal Venture • 2020 Figma Design System & Landing Page"
              projectLink="/projects/BootstrapDesign"
            />
            <CardHalfWidth 
              title="Candor" 
              subTitle="Proffessional Work • 2020 Motion Design & Prototyping"
              projectLink="/projects/Candor"
            />
          </GridHalf>

          <CardFullWidth 
            title="Underwriters Labratory- Marks Hub" 
            subTitle="Proffessional Work • 2019 - 2020 • Rebrand & Front End   Dev"
            projectLink="/projects/MarksHub"
          />
          <CardFullWidth 
            title="Echo Ship" 
            subTitle="Proffessional Work • 2019 - 2020 • Sales Prototype & Pattern Library"
            projectLink="/projects/EchoLogistics"
          />
          <GridHalf>
            <CardHalfWidth 
              title="Point of Care" 
              subTitle="Personal Venture • 2020 • Figma Design System & Landing Page"
              projectLink="/projects/PointOfCare"
            />
            <CardHalfWidth 
              title="Caremerge Family App" 
              subTitle="Proffessional Work • 2020 Motion Design & Prototyping"   
              projectLink="/projects/FamilyApp"
            />
          </GridHalf>
          
          <CardFullWidth 
            title="Calendar Central" 
            subTitle="Proffessional Work • 2016 - 2018 • Product Design & User Testing"
            projectLink="/projects/CalendarCentral"
          />

        </Grid>
      </>
    </ThemeProvider>
  )
}

export default IndexPage


const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  max-width: 1110px;
  margin: 0 auto;
  margin-top: 48px;
`

const Header = styled.header`
  display: grid;

  .header__title {
    font-weight: bold;
    font-size: 72px;
    line-height: none;
  }
  .header__sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 132.02%;
  }
  .header__button {
    background: #000000;
    border-radius: 6px;

    padding: 8px 16px;

    font-weight: 600;
    font-size: 16px;
    color: #fff;
    width: 133px;
    text-align: center;
  }
`

const GridHalf = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin: 0 auto;
  }
`


