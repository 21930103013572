import * as React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
import { ArrowRight } from 'react-feather';

const CardHalfWidth = (props) => {
  return (
    <Grid>
      <div className="card__image"></div>
      <h2 className="card__title">{props.title}</h2>
      <p className="card__sub-title">{props.subTitle}</p>
      <StyledLink to={props.projectLink}>
        View More
        <ArrowRight />
      </StyledLink>
    </Grid>
  )
}

export default CardHalfWidth


const Grid = styled.div`
  display: grid;
  grid-template-rows: .8fr .1fr .1fr 38px;
  min-height: 656px;
  background-color: #F6F6F6;
  border-radius: 32px;
  padding: 48px;
  background: ${props => props.theme.mode === 
  'dark' ? 'linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.48) 100%)' : '#f6f6f6'};

  .card__image {
    background-color: #333333;
    border-radius: 8px;
    height: 525px;
  }
  .card__title {
    font-weight: bold;
    font-size: 36px;
    margin: 0px;
    padding: 32px 0px 8px 0px;
  }
  .card__sub-title{
    font-size: 24px;
    margin: 0px;
    padding: 0px 0px 24px 0px;
    line-height: 132.02%;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-weight: 600;
  font-size: 16px;
  background: #000000;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 8px 16px;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  svg {
    padding: 2px;
  }
`;