import * as React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
import { ArrowRight } from 'react-feather';

const CardFullWidth = (props) => {
  return (
    <Grid>
      <div className="card__image"></div>
      <h2 className="card__title">{props.title}</h2>
      <p className="card__sub-title">{props.subTitle}</p>
      <StyledLink to={props.projectLink}>
        View More
        <ArrowRight />
      </StyledLink>
    </Grid>
  )
}

export default CardFullWidth

const StyledLink = styled(props => <Link {...props} />)`
  grid-area: 3 / 2 / 3 / 3;
  align-self: center;
  justify-self: end;
  font-weight: 600;
  font-size: 16px;
  background: #000000;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  display: flex;
  justify-content: space-around;

  svg {
    padding: 2px;
  }

  @media (max-width: 900px) {
    grid-area: 4 / 1 / 4 / 1;
    width: 100%;
    justify-content: center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: .8fr .1fr .1fr;
  grid-template-columns: .8fr .2fr;
  ${'' /* background-color: #F6F6F6; */}
  border-radius: 32px;
  padding: 48px;
  margin-top: 32px;
  margin-bottom: 32px;

  background: ${props => props.theme.mode === 
  'dark' ? 'linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.48) 100%)' : '#f6f6f6'};




  .card__image {
    grid-area: 1 / 1 / 1 / 3;
    background-color: #333333;
    border-radius: 8px;
    height: 525px;
  }
  .card__title {
    font-weight: bold;
    font-size: 36px;
    margin: 0px;
    padding: 32px 0px 8px 0px;
    grid-area: 2 / 1 / 2 / 1;
    align-self: center;
  }
  .card__sub-title {
    font-size: 24px;
    margin: 0px;
    grid-area: 3 / 1 / 3 / 1;
    align-self: center;
  }

  @media (max-width: 900px) {
    grid-template-rows: .8fr .1fr .1fr .1fr;
    grid-template-columns: 1fr;

    .card__image {
      grid-area: 1 / 1 / 1 / 3;

    }
    .card__title {
      grid-area: 2 / 1 / 2 / 1;

    }
    .card__sub-title {
      grid-area: 3 / 1 / 3 / 1;
      padding: 0px 0px 16px 0px;
    }
  }

`





